export const IoSocketStatus = () => import('../../node_modules/nuxt-socket-io/lib/components/SocketStatus.js' /* webpackChunkName: "components/io-socket-status" */).then(c => wrapFunctional(c.default || c))
export const CommonsAutoFormatTextField = () => import('../../components/Commons/AutoFormatTextField.vue' /* webpackChunkName: "components/commons-auto-format-text-field" */).then(c => wrapFunctional(c.default || c))
export const CommonsBankCard = () => import('../../components/Commons/BankCard.vue' /* webpackChunkName: "components/commons-bank-card" */).then(c => wrapFunctional(c.default || c))
export const CommonsChangeLanguageLoading = () => import('../../components/Commons/ChangeLanguageLoading.vue' /* webpackChunkName: "components/commons-change-language-loading" */).then(c => wrapFunctional(c.default || c))
export const CommonsChipCurrency = () => import('../../components/Commons/ChipCurrency.vue' /* webpackChunkName: "components/commons-chip-currency" */).then(c => wrapFunctional(c.default || c))
export const CommonsCopyButton = () => import('../../components/Commons/CopyButton.vue' /* webpackChunkName: "components/commons-copy-button" */).then(c => wrapFunctional(c.default || c))
export const CommonsCustomQrCode = () => import('../../components/Commons/CustomQrCode.vue' /* webpackChunkName: "components/commons-custom-qr-code" */).then(c => wrapFunctional(c.default || c))
export const CommonsDatePicker = () => import('../../components/Commons/DatePicker.vue' /* webpackChunkName: "components/commons-date-picker" */).then(c => wrapFunctional(c.default || c))
export const CommonsDownloadBanner = () => import('../../components/Commons/DownloadBanner.vue' /* webpackChunkName: "components/commons-download-banner" */).then(c => wrapFunctional(c.default || c))
export const CommonsDownloadByOs = () => import('../../components/Commons/DownloadByOs.vue' /* webpackChunkName: "components/commons-download-by-os" */).then(c => wrapFunctional(c.default || c))
export const CommonsDownloadPage = () => import('../../components/Commons/DownloadPage.vue' /* webpackChunkName: "components/commons-download-page" */).then(c => wrapFunctional(c.default || c))
export const CommonsFilterForm = () => import('../../components/Commons/FilterForm.vue' /* webpackChunkName: "components/commons-filter-form" */).then(c => wrapFunctional(c.default || c))
export const CommonsGameIframeEmbed = () => import('../../components/Commons/GameIframeEmbed.vue' /* webpackChunkName: "components/commons-game-iframe-embed" */).then(c => wrapFunctional(c.default || c))
export const CommonsGameLoadFailed = () => import('../../components/Commons/GameLoadFailed.vue' /* webpackChunkName: "components/commons-game-load-failed" */).then(c => wrapFunctional(c.default || c))
export const CommonsInviteAndEarnBanner = () => import('../../components/Commons/InviteAndEarnBanner.vue' /* webpackChunkName: "components/commons-invite-and-earn-banner" */).then(c => wrapFunctional(c.default || c))
export const CommonsInviteAndEarnBannerDesktop = () => import('../../components/Commons/InviteAndEarnBannerDesktop.vue' /* webpackChunkName: "components/commons-invite-and-earn-banner-desktop" */).then(c => wrapFunctional(c.default || c))
export const CommonsLanguageSwitcher = () => import('../../components/Commons/LanguageSwitcher.vue' /* webpackChunkName: "components/commons-language-switcher" */).then(c => wrapFunctional(c.default || c))
export const CommonsLoading = () => import('../../components/Commons/Loading.vue' /* webpackChunkName: "components/commons-loading" */).then(c => wrapFunctional(c.default || c))
export const CommonsMaintenance = () => import('../../components/Commons/Maintenance.vue' /* webpackChunkName: "components/commons-maintenance" */).then(c => wrapFunctional(c.default || c))
export const CommonsNoSupportPage = () => import('../../components/Commons/NoSupportPage.vue' /* webpackChunkName: "components/commons-no-support-page" */).then(c => wrapFunctional(c.default || c))
export const CommonsNotFoundPage = () => import('../../components/Commons/NotFoundPage.vue' /* webpackChunkName: "components/commons-not-found-page" */).then(c => wrapFunctional(c.default || c))
export const CommonsNuxtImage = () => import('../../components/Commons/NuxtImage.vue' /* webpackChunkName: "components/commons-nuxt-image" */).then(c => wrapFunctional(c.default || c))
export const CommonsPhoneInput = () => import('../../components/Commons/PhoneInput.vue' /* webpackChunkName: "components/commons-phone-input" */).then(c => wrapFunctional(c.default || c))
export const CommonsPopup = () => import('../../components/Commons/Popup.vue' /* webpackChunkName: "components/commons-popup" */).then(c => wrapFunctional(c.default || c))
export const CommonsSkeletonLoader = () => import('../../components/Commons/SkeletonLoader.vue' /* webpackChunkName: "components/commons-skeleton-loader" */).then(c => wrapFunctional(c.default || c))
export const CommonsThemeMode = () => import('../../components/Commons/ThemeMode.vue' /* webpackChunkName: "components/commons-theme-mode" */).then(c => wrapFunctional(c.default || c))
export const Theme0ContentPageFooter = () => import('../../components/Theme0/ContentPageFooter.vue' /* webpackChunkName: "components/theme0-content-page-footer" */).then(c => wrapFunctional(c.default || c))
export const Theme0Footer = () => import('../../components/Theme0/Footer.vue' /* webpackChunkName: "components/theme0-footer" */).then(c => wrapFunctional(c.default || c))
export const Theme0Menubar = () => import('../../components/Theme0/Menubar.vue' /* webpackChunkName: "components/theme0-menubar" */).then(c => wrapFunctional(c.default || c))
export const Theme0Nav = () => import('../../components/Theme0/Nav.vue' /* webpackChunkName: "components/theme0-nav" */).then(c => wrapFunctional(c.default || c))
export const Theme0NavContainer = () => import('../../components/Theme0/NavContainer.vue' /* webpackChunkName: "components/theme0-nav-container" */).then(c => wrapFunctional(c.default || c))
export const Theme0PromotionPage = () => import('../../components/Theme0/PromotionPage.vue' /* webpackChunkName: "components/theme0-promotion-page" */).then(c => wrapFunctional(c.default || c))
export const Theme0Submenu = () => import('../../components/Theme0/Submenu.vue' /* webpackChunkName: "components/theme0-submenu" */).then(c => wrapFunctional(c.default || c))
export const CommonsPicesCircle = () => import('../../components/Commons/Pices/Circle.vue' /* webpackChunkName: "components/commons-pices-circle" */).then(c => wrapFunctional(c.default || c))
export const CommonsPicesStraight = () => import('../../components/Commons/Pices/Straight.vue' /* webpackChunkName: "components/commons-pices-straight" */).then(c => wrapFunctional(c.default || c))
export const Theme0MobileFooter = () => import('../../components/Theme0/Mobile/Footer.vue' /* webpackChunkName: "components/theme0-mobile-footer" */).then(c => wrapFunctional(c.default || c))
export const Theme0MobileNavBar = () => import('../../components/Theme0/Mobile/NavBar.vue' /* webpackChunkName: "components/theme0-mobile-nav-bar" */).then(c => wrapFunctional(c.default || c))
export const Theme0PagesPlayerProfile = () => import('../../components/Theme0/Pages/PlayerProfile.vue' /* webpackChunkName: "components/theme0-pages-player-profile" */).then(c => wrapFunctional(c.default || c))
export const Theme0PagesWithdrawalApply = () => import('../../components/Theme0/Pages/WithdrawalApply.vue' /* webpackChunkName: "components/theme0-pages-withdrawal-apply" */).then(c => wrapFunctional(c.default || c))
export const Theme0DesktopPagesDepositPage = () => import('../../components/Theme0/Desktop/Pages/DepositPage.vue' /* webpackChunkName: "components/theme0-desktop-pages-deposit-page" */).then(c => wrapFunctional(c.default || c))
export const Theme0DesktopPagesGamePage = () => import('../../components/Theme0/Desktop/Pages/GamePage.vue' /* webpackChunkName: "components/theme0-desktop-pages-game-page" */).then(c => wrapFunctional(c.default || c))
export const Theme0DesktopPagesMessage = () => import('../../components/Theme0/Desktop/Pages/Message.vue' /* webpackChunkName: "components/theme0-desktop-pages-message" */).then(c => wrapFunctional(c.default || c))
export const Theme0DesktopPagesProfilePage = () => import('../../components/Theme0/Desktop/Pages/ProfilePage.vue' /* webpackChunkName: "components/theme0-desktop-pages-profile-page" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
